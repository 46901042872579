import { Image, useLayoutEffect } from '@loveholidays/design-system';
import React, { useState } from 'react';

import { useAppContext } from '@Client/context/contexts';
import { ClassNameProps } from '@ComponentProps';

interface CarrierDetails {
  carrierCode: string;
  carrierName: string;
}
interface AirlineLogoProps extends ClassNameProps, CarrierDetails {
  fallback?: React.FC<CarrierDetails & ClassNameProps>;
}

const defaultFallback: AirlineLogoProps['fallback'] = ({ className, carrierName }) => (
  <strong
    data-id="airline-logo"
    className={className}
    sx={{
      display: 'inline-block',
      fontWeight: 'bold',
    }}
  >
    {carrierName}
  </strong>
);

const AIRLINE_PATH = '/airline-logos/';

export const AirlineLogo: React.FC<AirlineLogoProps> = ({
  carrierCode,
  carrierName,
  className,
  fallback,
}) => {
  const {
    site: { assetDomain },
  } = useAppContext();
  const [displayFallback, setDisplayFallback] = useState(false);

  useLayoutEffect(() => {
    setDisplayFallback(false);
  }, [carrierCode]);

  return displayFallback ? (
    (fallback ?? defaultFallback)({ className, carrierCode, carrierName })
  ) : (
    <Image
      data-id="airline-logo"
      className={className}
      fluid={false}
      sx={{
        width: 'auto',
        maxWidth: '85px',
        maxHeight: '20px',
        display: 'inline-block',
        verticalAlign: 'middle',
        objectFit: 'contain',
      }}
      src={`${assetDomain}${AIRLINE_PATH}${carrierCode}.png`}
      alt={carrierName}
      width={85}
      height={20}
      onError={() => setDisplayFallback(true)}
    />
  );
};
